import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  brandColours,
  brandFonts,
  breakpointSizes,
  fluidFontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  standardTransition,
} from '../styles';
import { Link, Svg } from './ui';
import rightArrow from '../images/right-arrow.inline.svg';
import pdfIcon from '../images/pdf-icon.inline.svg';

const StyledCard = styled.article`
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 ${standardColours.transparentBlack(0.2)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const StyledInner = styled.div`
  ${({ isCareerCard, isCompanyCard }) => {
    if (isCareerCard) {
      return css`
        display: grid;

        ${minBreakpointQuery.xxlarge`
          grid-template-columns: 300px 1fr;
        `}

        ${maxBreakpointQuery.xxlarge`
          grid-template-rows: 250px 1fr;
          height: 100%;
        `}
      `;
    } else if (isCompanyCard) {
      return css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `;
    } else {
      return css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `;
    }
  }}
`;

const StyledTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${brandColours.tertiary};

  ${({ isCareerCard, isNewsCard }) => {
    if (isCareerCard) {
      return css`
        padding: 0;
        height: 250px;
        width: 100%;

        ${minBreakpointQuery.xxlarge`
          height: 100%;
        `}
      `;
    } else if (isNewsCard) {
      return css`
        padding: 0;
        height: 100%;
        min-height: 238px;

        ${minBreakpointQuery.mlarge`
          min-height: 300px;
          max-height: 300px;
        `}

        ${minBreakpointQuery.xxlarge`
          min-height: 238px;
          max-height: 238px;
        `}
      `;
    } else {
      return css`
        height: 250px;
      `;
    }
  }}
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 220px;
  max-height: 160px;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  ${({ isCareerCard, isNewsCard }) => {
    if (isCareerCard) {
      return css`
        max-height: 170px;
        width: auto;
        margin: auto;
      `;
    } else if (isNewsCard) {
      return css`
        height: 100%;
      `;
    } else {
      return css``;
    }
  }}
`;

const StyledPdfIcon = styled(Svg)`
  ${maxBreakpointQuery.tsmall`
    margin: 30px 0;
  `}
`;

const StyledContent = styled.div`
  background-color: ${standardColours.white};
  padding: 40px;
  color: ${brandColours.secondary};

  ${({ isCareerCard, isCompanyCard }) => {
    if (isCareerCard) {
      return css`
        padding: 50px 36px;
        display: grid;

        ${minBreakpointQuery.xxlarge`
          grid-template-columns: 1fr 204px;
          column-gap: 15px;
        `}
      `;
    } else if (isCompanyCard) {
      return css`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `;
    } else {
      return css`
        height: 100%;
      `;
    }
  }}
`;

const StyledHeadingWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 20px;

  ${({ isCareerCard }) => {
    if (isCareerCard) {
      return css`
        ${minBreakpointQuery.small`
          margin-bottom: 25px;
        `}

        ${minBreakpointQuery.medium`
          margin-bottom: 30px;
        `}

        ${minBreakpointQuery.large`
          margin-bottom: 35px;
        `}

        ${minBreakpointQuery.xlarge`
          margin-bottom: 40px;
        `}
      `;
    }
  }}
`;

const StyledHighlight = styled.div`
  ${({ isCareerCard }) => {
    if (!isCareerCard) {
      return css`
        background: ${brandColours.primary};
        width: 10px;
        height: 100%;
        position: absolute;
        left: -40px;
      `;
    }
  }}
`;

const StyledHeading = styled.h2`
  ${fluidFontSize(
    '20px',
    '28px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  font-family: ${brandFonts.tertiary};
`;

const StyledLink = styled.a``;

const StyledText = styled.p``;

const StyledBottom = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${brandColours.tertiary};
  padding: 28px;
  height: 68px;
  justify-content: center;

  &:hover {
    svg {
      right: 24px;
      transition: ${standardTransition('right')};
    }
  }
`;

const StyledTag = styled.span`
  color: ${brandColours.secondary};
`;

const StyledArrow = styled(Svg)`
  position: absolute;
  right: 28px;
  top: 28px;
`;

const CardLink = ({ url, pdf, slug, linkTag, title, children }) => {
  return (
    <StyledLink
      as={url || pdf ? 'a' : slug ? Link : 'div'}
      {...((url || pdf) && {
        href: url || pdf.url,
        target: '_blank',
        rel: 'noopener',
      })}
      {...(slug && { to: `/news-resources/${slug}` })}
      title={`${linkTag} ${pdf ? 'report on' : 'about'} ${title}`}
    >
      {children}
    </StyledLink>
  );
};

const Card = ({
  image,
  url,
  text,
  excerpt,
  pdf,
  title,
  heading,
  linkTag,
  isCareerCard,
  isCompanyCard,
  isNewsCard,
  slug,
  children,
  ...props
}) => {
  return (
    <StyledCard {...props}>
      <StyledInner isCareerCard={isCareerCard} isCompanyCard={isCompanyCard}>
        <StyledTop isCareerCard={isCareerCard} isNewsCard={isNewsCard}>
          {image &&
            (image.mimeType === 'image/svg+xml' ? (
              <StyledImage
                src={image.url}
                loading="lazy"
                aria-label={image.title}
                width={image.width ? image.width : 360}
                height={image.height ? image.height : 238}
              />
            ) : (
              <StyledGatsbyImage
                image={image.gatsbyImageData}
                alt={image.title}
                objectFit="cover"
                objectPosition="0 0"
                isCareerCard={isCareerCard}
                isNewsCard={isNewsCard}
              />
            ))}
          {pdf && <StyledPdfIcon image={pdfIcon} aria-label={'PDF Icon'} />}
        </StyledTop>
        <StyledContent
          isCareerCard={isCareerCard}
          isCompanyCard={isCompanyCard}
        >
          {heading && (
            <StyledHeadingWrapper isCareerCard={isCareerCard}>
              <StyledHighlight isCareerCard={isCareerCard}></StyledHighlight>
              <StyledHeading>
                <CardLink
                  url={url}
                  pdf={pdf}
                  slug={slug}
                  linkTag={linkTag}
                  title={heading || title}
                >
                  {heading}
                </CardLink>
              </StyledHeading>
            </StyledHeadingWrapper>
          )}
          {text && <StyledText>{text.text}</StyledText>}
          {excerpt && <StyledText>{excerpt}</StyledText>}
          {children}
        </StyledContent>
      </StyledInner>
      {linkTag && (
        <StyledBottom>
          <CardLink
            url={url}
            pdf={pdf}
            slug={slug}
            linkTag={linkTag}
            title={heading || title}
          >
            <StyledTag>{linkTag}</StyledTag>
            <StyledArrow image={rightArrow} aria-label="Right Arrow" />
          </CardLink>
        </StyledBottom>
      )}
    </StyledCard>
  );
};

export default Card;
